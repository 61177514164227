import { memo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAppContext } from '../../context'

export default memo(function PrivateRoute({ children }) {
    //这个组件做的事就是校验是否已登录 登录了 显示对应组件 没有登录跳转登录的
    const [ctx] = useAppContext()
    const allData = ctx.getIn(['allData'])
    const { pathname } = useLocation();
    //未登录且路径就是/login
    if (!allData) {
        return <Navigate to='/login' />;
    }
    //已登录路径是/login 跳转首页
    if (pathname === '/login' && allData) {
        return <Navigate to='/' />
    }
    return children
})
