import {React,useState} from 'react';
import './index.css'
import { RiDeleteBinFill } from 'react-icons/ri'
import { BiEdit } from 'react-icons/bi'
import {BsFillMarkdownFill} from 'react-icons/bs'
import { useAppContext } from '../../context';
import { Modal,message,Form ,Drawer,Space,Button} from 'antd';
import { nodeDataService } from '../../service';
import TreeNode from '../Form/TreeNode';
import { fromJS } from 'immutable';
import _ from 'lodash';
import MyDraver from '../../common/MyDraver';
import { MarkDown } from '../MarkDown';
import MDEditor from "@uiw/react-md-editor";
import {BiDetail} from 'react-icons/bi'
import { commonService } from '../../service';


const Index = ({data}) => {
    const [ctx,setCtx] = useAppContext()
    const show = ctx.getIn(['allData','show'])
    const nodeData = ctx.getIn(['allData','NodeData'])
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [operateId, setOperateId] = useState();
    const [form] = Form.useForm()
    const [editData, setEditData] = useState(false);
    const [currentMarkDownData, setCurrentMarkDownData] = useState();
    const [editNodeDataContent, setEditNodeDataContent] = useState(false);
    const [maxContent, setMaxContent] = useState(false);
    const [editNodeData, setEditNodeData] = useState({});

    const edit=(e,id)=>{
        // console.log('edit',id)
        setEditData(fromJS(_.find(nodeData,item=>item.Id===id)))
        setEditNodeData(_.find(nodeData,item=>item.Id===id))
        setOperateId(id)
        setEditVisible(true)
        e.stopPropagation();
    }
    const afterSuccess = ()=>{
        setDeleteVisible(false);
        setEditVisible(false)
        setEditNodeDataContent(false)
        form.resetFields();
        message.success("操作成功")
        commonService.getAllData({}).then(data=>{
            if(data.Code===200){
                setCtx(['allData',data.Data])
                setCtx(['operateNodeData',{openNodeData:false}])
            }
          })

        // ReloadPage()
        // commonService.getAllData({}).then(data=>{
        //   if(data.Code===200){
        //       data.Data.show=true
        //       setCtx(['allData',data.Data])
        //   }
        // })
    }
    const deleteById=(e,id)=>{
        setOperateId(id)
        setDeleteVisible(true)
        e.stopPropagation();
    }
    const doDelete=()=>{
        nodeDataService.deleteNodeDataById(operateId).then(data=>{
            if(data.Code){
                afterSuccess()
            }
        })
    }
    const updateNodeData=values=>{
        // data.Data = editNodeData.Data;
        //更新
        values.Data = data.Data
        nodeDataService.updateNodeData(values).then(data=>{
            if(data.Code){
                afterSuccess()
            }
        })
    }
    const markDown =(e,id)=>{
        setOperateId(id)
        setEditNodeDataContent(true)
        setCurrentMarkDownData(data.Data)
    }
    const maxMarkdown =(e,id)=>{
        setOperateId(id)
        setMaxContent(true)
        const values = _.find(nodeData,obj=>obj.Id===id)
        setCurrentMarkDownData(values.Data)
    }
    // 处理Data即markDown
    const markDownSubmit = ()=>{
        data.Data =  currentMarkDownData
        //更新
        nodeDataService.updateNodeData(data).then(data=>{
            if(data.Code){
                afterSuccess()
            }
        })
    }
    return (
        <>
         <div>
            <div className={data.Type===0?"":"PanelWrapper margin10"}>
                {data.Type!==0?
                <div className={'commonOperate  itemWrapper'+(show?' hidden':'')}>
                    <div onClick={(e)=>edit(e,data.Id)} ><BiEdit/></div>
                    <div onClick={(e)=>deleteById(e,data.Id)}><RiDeleteBinFill/></div>
                </div>:''
                }
                {data.Type===0?"":<div className='contentTitle'>{data.Title}</div>}
                {
                    data.Type===0?"":<div  className="markDown"><div onClick={(e)=>markDown(e,data.Id)} ><BsFillMarkdownFill/></div>
                    <div onClick={(e)=>maxMarkdown(e,data.Id)} ><BiDetail/></div></div>
                }
                {
                    (()=>{switch (data.Type) {
                        case 0:
                            return <div className='paneltitle'><div># {data.Title}</div>
                            <div className={'titleWrapper paneltitle'+(show?' hidden':'')}>
                                <div onClick={(e)=>edit(e,data.Id)} ><BiEdit/></div>
                                <div onClick={(e)=>deleteById(e,data.Id)}><RiDeleteBinFill/></div>
                            </div>
                            </div>
                        case 1:
                            return <div data-color-mode="dark">
                                       <MDEditor.Markdown
                                            style={{ padding: 15,height:500,overflowY:'auto' }}
                                            source={data.Data}
                                            linkTarget="_blank"
                                        />
                                    </div>
                        default:
                            break;
                    }})()
            }
            </div>
         </div>
         <MyDraver title={'编辑NodeData'} drawerVisible={editVisible} setDrawerVisible={setEditVisible}  editData={editData} form={form}>
            <TreeNode form={form} finishAddOrUpdate={updateNodeData} data={editNodeData}/>
         </MyDraver>
         <Modal
                    title="删除此NodeData么"
                    open={deleteVisible}
                    onOk={doDelete}
                    onCancel={()=>setDeleteVisible(false)}
                    okText="确认"
                    cancelText="取消"
                >
                    <p>是否删除，此操作不可逆！</p>
          </Modal>
            <Drawer
            forceRender
            destroyOnClose={true}
            title="编辑markDown"
            width={1440}
            onClose={()=>setEditNodeDataContent(false)}
            open={editNodeDataContent}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                <Space>
                    <Button onClick={()=>setEditNodeDataContent(false)}>取消</Button>
                    <Button onClick={markDownSubmit} type="primary">
                        提交
                    </Button>
                </Space>
            }
        >
           <MarkDown data={currentMarkDownData} onChange={setCurrentMarkDownData} height={700}/>
        </Drawer>
        <Drawer
            forceRender
            destroyOnClose={true}
            title="详情"
            width={1440}
            onClose={()=>setMaxContent(false)}
            open={maxContent}
            bodyStyle={{ paddingBottom: 10 }}
            extra={
                <Space>
                    <Button onClick={()=>setMaxContent(false)}>关闭</Button>
                </Space>
            }
        >
           <div data-color-mode="dark">
                <MDEditor.Markdown
                    style={{ padding: 15,overflowY:'auto' }}
                    source={data.Data}
                    linkTarget="_blank"
                />
            </div>
        </Drawer>
        </>
    );
}

export default Index;
