import React from 'react'
import { Form, Input, Button,Row,Col } from 'antd';
//导入antd 样式
//导入antd 图标
import { LockOutlined } from '@ant-design/icons';
import localStorage from 'localStorage'
import './index.css'

//导入userCommon
// import { userCommon } from '../../services';

export default function Login() {

  const onFinish = values => {
    localStorage.setItem("authorization",values.authorization)
    window.location.href = '/#/home'
  }
    return <>
    <div className='container' style={{height:window.innerHeight}}>
        <Row style={{paddingTop:300}}>
          <Col span={6} offset={9}>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
      
        <Form.Item
          name="authorization"
          rules={[{ required: true, message: '输入认证信息' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="输入认证信息"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" style={{width:'100%'}}>
            登录
          </Button>
        </Form.Item>
      </Form>
      </Col>
      </Row>
    </div>
  </>
}
