import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
// import { Provider } from 'react-redux';
import { Provider } from './context'
import './index.css';
import App from './App';
import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd'
// import store from './store'
ReactDOM.render(
  <ConfigProvider locale={zhCN} theme={{ token: { colorPrimary:"#1668dc",colorBgBase:'#1e293b',colorTextBase:'#bfd5e1',colorBorder:'#148e6d',colorBorderSecondary:'#148e6d'} }} >
    <Provider >
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  </ConfigProvider>, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
