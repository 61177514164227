import {Head,Foot,Body} from '../'
import { Layout,Form ,message} from 'antd';
import {useEffect,useState } from 'react'
import { Drawer, Button, Space } from 'antd';
import Chat from '../Chat';
import './index.css'
import MyDraver from '../../common/MyDraver'
import SheetTree from '../Form/SheetTree'
import {sheetTreeService,nodeDataService} from '../../service'
import {RandomColor} from '../../common/util'
import { Route,Routes} from 'react-router-dom'
import NodeDataBody from '../NodeDataBody'
import { commonService } from '../../service'
import { useAppContext } from '../../context'
import TreeNode from  '../Form/TreeNode';
export default function Home(){
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerNodeVisible, setDrawerNodeVisible] = useState(false);
  const [drawerOpenAiVisible, setDrawerOpenAiVisible] = useState(false);

  const [editNodeData] = useState();
  const [editData] = useState();
  const [form] = Form.useForm()
  const [formNodeData] = Form.useForm()
  const [ctx,setCtx] = useAppContext()
  const afterSuccess  = ()=>{
    setDrawerVisible(false);
    setDrawerNodeVisible(false)
    form.resetFields();
    formNodeData.resetFields()
    message.success("操作成功")
    // ReloadPage()
    commonService.getAllData({}).then(data=>{
      if(data.Code===200){
          // data.Data.show=true
          setCtx(['allData',data.Data])
          setCtx(['operateNodeData',{openNodeData:false}])
      }
    })
  }
  const finishAddOrUpdate = values => {
    //新增
    values.Color = RandomColor()
    sheetTreeService.addSheetCheat(values).then(data=>{
      if (data.Code === 200) {
        afterSuccess()
        
      }
    })
  }
  const finishNodeDataAddOrUpdate = values => {
    //新增 nodeData
    nodeDataService.addNodeData(values).then(data=>{
      if (data.Code === 200) {
        afterSuccess() 
      }
    })
  }
  const onKeyDown = (e)=>{
    // alt+n 新增sheetTree
    if (e.altKey &&!e.shiftKey&& e.keyCode === 78){
        setDrawerVisible(true)
    }
    if (e.shiftKey &&e.altKey&& e.keyCode === 78){
      // shift+alt+n 新增NodeData
      setDrawerNodeVisible(true);
    }
    if (e.ctrlKey && e.keyCode === 75){
      // 搜索
      // setSearchVisible(true)
      // 阻止默认事件
      e.preventDefault()
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown); // 添加全局事件
    return () => {
      window.removeEventListener('keydown', onKeyDown); // 销毁
    };
    
  }, []);
    return (<>
     <Layout  theme="light">
      <div className='header divcenter'><Head addNodeData={()=>setDrawerNodeVisible(true)} addSheetTree={()=>setDrawerVisible(true)} openAiShow={()=>setDrawerOpenAiVisible(true)}/></div>
      <Routes>
        <Route path='/home/nodeData/:id' element={<NodeDataBody/>}></Route>
        <Route path='*' element={<Body/>}></Route>
      </Routes>
      <div className='foot divcenter'><Foot/></div>
    </Layout>
    <MyDraver title={'新增sheetTree'} drawerVisible={drawerVisible} setDrawerVisible={setDrawerVisible}  editData={editData} form={form}><SheetTree form={form} finishAddOrUpdate={finishAddOrUpdate}/></MyDraver>
    <MyDraver title={'新增NodeData'} drawerVisible={drawerNodeVisible} setDrawerVisible={setDrawerNodeVisible}  editData={editNodeData} form={formNodeData}><TreeNode form={formNodeData} finishAddOrUpdate={finishNodeDataAddOrUpdate} data={{'Type':1}}/></MyDraver>
    <>
            <Drawer
                forceRender
                destroyOnClose={true}
                title='OpenAi Api'
                width={1000}
                onClose={()=>setDrawerOpenAiVisible(false)}
                open={drawerOpenAiVisible}
                placement='left'
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={()=>setDrawerOpenAiVisible(false)}>取消</Button>
                    </Space>
                }
            >
             <Chat/>
            </Drawer>
        </>
    </>)
}