import {React,useState,useEffect} from 'react'
import {  Form, Col, Row, Input, Select } from 'antd'
import {useAppContext} from '../../context'
import {useParams} from 'react-router-dom'
import _ from 'lodash'


// tree node
export default function TreeNode({form,finishAddOrUpdate,data}) {
    // console.log('TreeNode',data.toJS())
    
    const [ctx] = useAppContext()
    const [secondLevel,setSecondLevel] = useState([])
    const [load,setLoad] = useState(false)
    const [showParentTitle,setShowParentTitle] = useState(true)
    const [defalutTreeNodeId,setDefalutTreeNodeId] = useState()
    const [allParentData,setAllParentData] = useState([])
    const allData = ctx.getIn(['allData'])
    const params = useParams()
    const changeTitleData=(parentId)=>{
        const secondLevelData = _.filter(allData.SheetTree,item=>item.NodeType!==0)
        const allNodeDataTitle =   _.filter(allData.NodeData,item=>item.Type===0)
        const toFilterData = allNodeDataTitle.map(item=>({label:_.find(secondLevelData,obj=>obj.Id===item.TreeNodeId).NodeName+' < '+item.Title ,value:item.Id,parentId:item.TreeNodeId}))
        setAllParentData(_.filter(toFilterData,obj=>obj.parentId===parseInt(parentId)))
    }
    useEffect(()=>{
        if(!load){
            const secondLevelData = _.filter(allData.SheetTree,item=>item.NodeType!==0)
            const firstLevelData = _.filter(allData.SheetTree,item=>item.NodeType===0)
            const allNodeDataTitle =   _.filter(allData.NodeData,item=>item.Type===0)
            setAllParentData(allNodeDataTitle.map(item=>({label:_.find(secondLevelData,obj=>obj.Id===item.TreeNodeId).NodeName+' < '+item.Title+' '+item.Id ,value:item.Id,parentId:item.TreeNodeId})))
            setSecondLevel(secondLevelData.map(item=>({label:_.find(firstLevelData,obj=>obj.Id===item.ParentId).NodeName+' < '+item.NodeName,value:item.Id})))
            if(params.id){
                setDefalutTreeNodeId(parseInt(params.id))
               
            }
            setLoad(true)
        }
    },[secondLevel,defalutTreeNodeId,allData.SheetTree,params.id,load,allParentData,allData.NodeData,data])
    const changeShowParentTitle=val=>{
        setShowParentTitle(val===0)
        let cParentId = params['*'].split("/").pop()
        // 设置默认
        form.setFieldsValue({TreeNodeId:parseInt(cParentId)})
        // 筛选出副标题
        console.log("筛选出副标题",_.filter(allData.NodeData,item=>item.Type===0&&item.TreeNodeId===parseInt(cParentId)))
        const secondLevelData = _.filter(allData.SheetTree,item=>item.NodeType!==0)
        setAllParentData(_.filter(allData.NodeData,item=>item.Type===0&&item.TreeNodeId===parseInt(cParentId)).map(item=>({label:_.find(secondLevelData,obj=>obj.Id===item.TreeNodeId).NodeName+' < '+item.Title ,value:item.Id,parentId:item.TreeNodeId})))
    }
    return (
        <Form labelCol={{
            span: 5,
        }} form={form} layout="horizontal" hideRequiredMark onFinish={finishAddOrUpdate}>
            <Form.Item name='Id' label='主键ID' hidden>
                <Input />
            </Form.Item>
            <Row gutter={16}>
            <Col span={24}>
                    <Form.Item
                        name="Title"
                        label="标题"
                        rules={[{ required: true, message: '请输入标题' }]}
                    >
                     <Input placeholder="请输入标题" />
                    </Form.Item>
                </Col>
                
                
            </Row>
            <Row gutter={16}>
            <Col span={24}>
                    <Form.Item
                        name="Type"
                        label="节点类型"
                        rules={[{ required: true, message: '请选择节点类型' }]}
                    >
                         <Select onSelect={changeShowParentTitle}>
                            <Select.Option value={0}>一级标题</Select.Option>
                            {/* <Select.Option value={1}>代码片段</Select.Option> */}
                            <Select.Option value={1}>markDown</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
             <Col span={24}>
                    <Form.Item
                        name="TreeNodeId"
                        label="父sheetCheat二级Id"
                        rules={[{ required: true, message: '请选择父sheetCheat二级' }]}
                    >
                       <Select 
                       onSelect={changeTitleData}
                        showSearch
                        placeholder="输入筛选"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={secondLevel}
                       />
                    </Form.Item>
                </Col>
             </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        // hidden
                        name="ParentId"
                        label="父标题"
                        initialValue={params.id}
                        hidden = {showParentTitle}
                        rules={[{ required: false, message: '请选择父标题' }]}
                    >
                       <Select 
                        showSearch
                        placeholder="输入筛选"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={allParentData}
                       />
                    </Form.Item>
                </Col>
                
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="Remark"
                        label="备注"
                        rules={[{ required: false, message: '请输入备注' }]}
                    >
                       <Input placeholder="请输入备注" />
                    </Form.Item>
                </Col>
                
            </Row>
            
        </Form>
    )
}
