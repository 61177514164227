import React from 'react';
import { Spin,Space } from 'antd';
const Loading = () => {
    return (
        <div className='loadingwarpper'>
            <Space
              direction="vertical"
              style={{
                width: '100%',
              }}
            >
            <Spin tip="" size="large">
                    <div className="content" />
            </Spin>
            </Space>
        </div>
    );
}

export default Loading;
