
// 获取随机颜色从颜色池
const RandomColor =()=>{
    const colorArray = ['#391875','#2d5d7b','#2b364a','#7a422c','#7a422c','#792c2d','#64405f','#0a564a','#254e8d','#7d6a38','#996666','#303','#000033','#0099CC']
    return colorArray[parseInt(Math.random() * colorArray.length)]; 
}
const ReloadPage =()=>{

    window.location.reload()
   
}
export  {RandomColor,ReloadPage}