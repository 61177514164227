import { Route, Routes } from 'react-router-dom'
import { Home } from '../'
import { memo } from 'react'
import PrivateRoute from './PrivateRoute'
import Login from '../Login'
//定义路由信息
export default memo (function AppRoute() {
    return (
        <>
            <Routes>
                {/* 兜底的路径 */}
                {/* <Route path='/home/*' element={<PrivateRoute children={<Home />} />}></Route> */}
                <Route path='/login' element={<Login />}></Route>
                <Route path='*' element={<PrivateRoute children={<Home />} />}>
                </Route>
            </Routes>
        </>
    )
})
