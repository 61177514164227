import {React,useEffect} from 'react';
import './index.css'
import {useParams} from 'react-router-dom'
import {useAppContext} from '../../context'
import {Empty } from 'antd'
import _ from 'lodash'
import PanelWrapper from '../PanelWrapper'
import { nodeDataService } from '../../service';
import { Col, Row } from 'antd';
import Loading from './Loading';
const Index = () => {
    // console.log('nodedatabody Render...')
    const params = useParams()
    const [ctx,setCtx] = useAppContext()
    const render = ctx.getIn(['openNodeData'])
    // console.log('render....',render)
    const nodeData = ctx.getIn(['allData','NodeData'])
    const currentNodeData = _.filter(nodeData,obj=>obj.TreeNodeId===parseInt(params.id))
    // 构建父子关系
    let firstLevelData = _.filter(currentNodeData,obj=>obj.Type===0)
    useEffect(()=>{
        if(!render)
            nodeDataService.getNodeDataByTreeNodeId(params.id).then(data=>{
                if(data.Code===200){
                    _.each(firstLevelData,item=>{
                        // 构建二级
                        item.children = _.filter(data.Data,obj=>obj.ParentId===parseInt(item.Id))
                    })
                    setCtx(['operateNodeData',{openNodeData:true}])
                }
            })
    },[render])
    
    // Type 0 标题 1 代码块 2 markDown
    return (
        <>
        {!render?<Loading/>:
            <div className='body padding10'>
            
                {_.isEmpty(firstLevelData)?<div className='verticalMiddle'><Empty/></div>:firstLevelData.map(item=>{
                    return <div key={item.Id}>
                            <Row>
                            <PanelWrapper data={item}/>
                            </Row>
                            <Row>{    
                            item.children?item.children.map(secondLevel=>{
                                return  <Col span={6} key={secondLevel.Id}><PanelWrapper data={secondLevel}/></Col>
                            }):''
                            
                    }</Row>
                    </div>
                })}
            
            </div>
        }
        </>
    );
}
export default Index;
