import {React,useState,useEffect} from 'react'
import {  Form, Col, Row, Input, Select } from 'antd'
import {useAppContext} from '../../context'
import _ from 'lodash'

// sheet tree form
export default function SheetTree({form,finishAddOrUpdate}) {
    const [ctx] = useAppContext()
    const [firstLevel,setFirstLevel] = useState([])
    const allData = ctx.getIn(['allData'])
    useEffect(()=>{
        let data = _.filter(allData.SheetTree,obj=>obj.NodeType === 0)
        data.unshift({Id:0,NodeName:'根节点(表示当前节点为一级节点)'})
        setFirstLevel(data.map(item=>({label:item.NodeName,value:item.Id})))
    },[allData.SheetTree])
    const selectNodeType = (val)=>{
        if(val===0||val==='0'){
            setFirstLevel([{value:0,label:'根节点(表示当前节点为一级节点)'}])
        }else{
           let filterData = _.filter(allData.SheetTree,obj=>obj.NodeType === 0)
            setFirstLevel(filterData.map(item=>({label:item.NodeName,value:item.Id})))
        }
    }
    return (
        <Form labelCol={{
            span: 5,
        }} form={form} layout="horizontal" hideRequiredMark onFinish={finishAddOrUpdate}>
            <Form.Item name='Id' label='主键ID' hidden>
                <Input />
            </Form.Item>
            <Form.Item name='Color' label='主键ID' hidden>
                <Input />
            </Form.Item>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="NodeName"
                        label="节点名称"
                        rules={[{ required: true, message: '请输入节点名称' }]}
                    >
                        <Input placeholder="请输入节点名称" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="NodeType"
                        label="节点层级"
                        rules={[{ required: true, message: '请选择节点类型' }]}
                    >
                         <Select onChange={selectNodeType}>
                            <Select.Option value={0}>一级</Select.Option>
                            <Select.Option value={1}>二级</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="NodeIcon"
                        label="节点图标"
                        rules={[{ required: false, message: '请输入节点图标' }]}
                    >
                        <Input placeholder="请输入节点图标" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="ParentId"
                        label="父节点"
                        rules={[{ required: false, message: '请选择父节点' }]}
                    >
                        <Select
                        showSearch
                        placeholder="输入筛选"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={firstLevel}
                        > 
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}
