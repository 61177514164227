import {React,useState} from 'react';
import './index.css'
import {AiTwotoneEdit} from 'react-icons/ai'
import { MdDeleteForever } from 'react-icons/md'
import ReactIcon from '../ReactIcon'
import _ from 'lodash'
import MyDraver from '../../common/MyDraver'
import {Form,message,Modal} from 'antd'
import SheetTree from '../Form/SheetTree'
import {sheetTreeService} from '../../service'
import {useAppContext} from '../../context'
import { fromJS } from 'immutable';
import { Link } from 'react-router-dom';
import { commonService } from '../../service';
const Index = ({data}) => {
  const [ctx,setCtx] = useAppContext()
  const allData = ctx.getIn(['allData'])
  const show = ctx.getIn(['allData','show'])
  const sheetTreeData=allData.SheetTree
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editData, setEditData] = useState(false);
  const [deleteVisible,setDeleteVisible] = useState(false)
  const [form] = Form.useForm()
  const [deleteId, setDeleteId] = useState();
  const afterSuccess  = ()=>{
    setDrawerVisible(false);
    setDeleteVisible(false)
    form.resetFields();
    message.success("操作成功")
    // ReloadPage()
    commonService.getAllData({}).then(data=>{
        if(data.Code===200){
            // data.Data.show=true
            setCtx(['allData',data.Data])
        }
      })
  }
    const edit = (e,id)=>{
        const tmp = _.find(sheetTreeData,obj=>obj.Id===id)
        setEditData(fromJS(tmp))
        setDrawerVisible(true)
        e.stopPropagation();
    }
    const deleteById = (e,id)=>{
        setDeleteId(id)
        setDeleteVisible(true)
        e.stopPropagation();
    }
    const doDelete=()=>{
        sheetTreeService.deleteSheetCheatById(deleteId).then(data=>{
        // delete
            if(data.Code===200){
                afterSuccess()
            }
        })
    }
    const updateSheetTree = values =>{
        // update
        sheetTreeService.updateSheetCheat(values).then(data=>{
            if(data.Code===200){
                afterSuccess()
            }
        })
    }
    const toDetail = (e,id) =>{
        setCtx(['operateNodeData',{openNodeData:false}])
    }
    return (
        <>
        <div className='blockWrapper' style={{"backgroundColor":data.Color}}>
            <div onClick={(e)=>edit(e,data.Id)} className={'editWrapper'+(show?' hidden':'')}><AiTwotoneEdit/></div>
            <div onClick={(e)=>deleteById(e,data.Id)} className={'deleteWrapper'+(show?' hidden':'')}><MdDeleteForever/></div>
            <Link onClick={e=>toDetail(e,data.Id)} to={`/home/nodeData/${data.Id}`}>
            <div className='block'>
            {!_.isEmpty(data.NodeIcon)?<ReactIcon iconStr={data.NodeIcon}/>:''}<span className='margin10'>{data.NodeName}</span>
            </div>
            </Link>
        </div>
       
        <MyDraver title={'编辑sheetTree(二级)'} drawerVisible={drawerVisible} setDrawerVisible={setDrawerVisible}  editData={editData} form={form}><SheetTree form={form} finishAddOrUpdate={updateSheetTree}/></MyDraver>
        <Modal
                    title="删除此sheetTree么"
                    open={deleteVisible}
                    onOk={doDelete}
                    onCancel={()=>setDeleteVisible(false)}
                    okText="确认"
                    cancelText="取消"
                >
                    <p>是否删除，此操作不可逆！</p>
                </Modal>
        </>
    );
}

export default Index;
