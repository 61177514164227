import { useState,memo,useEffect } from 'react'
import { AppRoute } from './component/routes'
import {useAppContext} from './context'
import {commonService} from './service'
export default memo(() => {
    const [isLoad, setIsLoad] = useState(false)
    const [ctx,setCtx] = useAppContext()
    const allData = ctx.getIn(['allData'])
    useEffect(
        () => {
    if(!allData&&!isLoad)
        commonService.getAllData({}).then(data=>{
            if(data.Code===200){
                data.Data.show=true
                setCtx(['allData',data.Data])
                setCtx(['operateNodeData',{openNodeData:false}])
            }
        }).finally(()=>{
            setIsLoad(true)
        })
    },[isLoad, allData, setCtx]
    )
    if(!isLoad)
        return (<></>)
    return (<>
        <AppRoute/>
            </>)
    }
)