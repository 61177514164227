import { createContext, useContextSelector } from 'use-context-selector';
import { useReducer } from 'react';
import { Map } from 'immutable'
const context = createContext();
const initialState = Map({})

const reducer = (preState, [type, data]) => {
    switch (type) {
        case 'allData':
            return Map({...preState.toObject(),allData:{...data}})
        case 'operateNodeData':
            return Map({...preState.toObject(),...data})
        default:
            return new Error()

    }
}
export const Provider = (props) => {
    const [ctx, setCtx] = useReducer(reducer, initialState)
    return <context.Provider value={[ctx, setCtx]}>{props.children}</context.Provider>
}

export const useAppContext = () => {
    return useContextSelector(context, v => v)
}