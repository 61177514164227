import {React,useState} from 'react'
import {useAppContext} from '../../context'
import ReactIcon from '../ReactIcon' 
import { Col, Row,Modal,message,Form } from 'antd';
import SecondBlock from '../SecondBlock'
import {AiTwotoneEdit} from 'react-icons/ai'
import { MdDeleteForever } from 'react-icons/md'
import MyDraver from '../../common/MyDraver'
import _ from 'lodash'
import './index.css'
import {sheetTreeService} from '../../service'
import { fromJS } from 'immutable'
import SheetTree from '../Form/SheetTree'
import { commonService } from '../../service';


export default function Body() {
  const [ctx,setCtx] = useAppContext()
  const [deleteId, setDeleteId] = useState()
  const [deleteVisible,setDeleteVisible] = useState(false)
  const allData = ctx.getIn(['allData'])
  const show = ctx.getIn(['allData','show'])
  const {SheetTree: sheetTreeData}=allData
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [form] = Form.useForm()

  const [editData, setEditData] = useState(false);
  let firstLevelData = _.filter(sheetTreeData,obj=>obj.NodeType===0)
  // 定死最多三级
  _.forEach(firstLevelData,secondLevel=>{
    // 二级
    const tmpChildren = _.filter(sheetTreeData,obj=>obj.ParentId===secondLevel.Id)
    secondLevel.children = tmpChildren
    // 三级
    _.forEach(tmpChildren,thirdLevel=>thirdLevel.children = _.filter(sheetTreeData,obj=>obj.ParentId===thirdLevel.Id))
  })
  const afterSuccess  = ()=>{
    setDrawerVisible(false);
    setDeleteVisible(false)
    form.resetFields();
    message.success("操作成功")
    // ReloadPage()
    commonService.getAllData({}).then(data=>{
      if(data.Code===200){
          // data.Data.show=true
          setCtx(['allData',data.Data])
      }
    })
  }
    const edit = (e,id)=>{
      console.log('edit',id)
      const tmp = _.find(sheetTreeData,obj=>obj.Id===id)
      setEditData(fromJS(tmp))
      setDrawerVisible(true)
      e.stopPropagation();
  }
  const deleteById = (e,id)=>{
      setDeleteId(id)
      setDeleteVisible(true)
      e.stopPropagation();
  }
  const doDelete=()=>{
      sheetTreeService.deleteSheetCheatById(deleteId).then(data=>{
      // delete
      if(data.Code===200){
          afterSuccess()
      }
      })
  }
  const updateSheetTree = values =>{
      // update
      sheetTreeService.updateSheetCheat(values).then(data=>{
          if(data.Code===200){
              afterSuccess()
          }
      })
  }
  return (
    <>
    <div className='body divcenter'>
      <div className='centerWrapper'>
      {
        firstLevelData.map(item=>{
          return <div  key={item.Id}>
            <div className='firstLevel'>
              <div style={{"position":"relative"}}>
                {!_.isEmpty(item.NodeIcon)?<ReactIcon iconStr={item.NodeIcon}/>:''}<span className='firstLevelTitle'>{item.NodeName}</span>
                <div onClick={(e)=>edit(e,item.Id)} className={'firstEditWrapper'+(show?' hidden':'')}><AiTwotoneEdit/></div>
                <div onClick={(e)=>deleteById(e,item.Id)} className={'firstDeleteWrapper'+(show?' hidden':'')}><MdDeleteForever/></div>
              </div>
              <Row>{item.children.map(secondLevel=>{
                return <Col span={4} key={secondLevel.Id}><SecondBlock data={secondLevel}/></Col>
              })}
              </Row>
            </div>
            
            </div>
        })
      }
      </div>
    </div>
    <MyDraver title={'编辑sheetTree(一级)'} drawerVisible={drawerVisible} setDrawerVisible={setDrawerVisible}  editData={editData} form={form}><SheetTree form={form} finishAddOrUpdate={updateSheetTree}/></MyDraver>
        <Modal
                    title="删除此sheetTree么"
                    open={deleteVisible}
                    onOk={doDelete}
                    onCancel={()=>setDeleteVisible(false)}
                    okText="确认"
                    cancelText="取消"
                >
                    <p>是否删除，此操作不可逆！</p>
        </Modal>
    </>
  )
}
