import axios from "axios";
import localStorage from 'localStorage'
import {
    message
}
from "antd";
const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization':localStorage.getItem('authorization')
    },
    timeout: 60 * 1000,
    responseType: 'json'
})

//请求拦截器
axiosClient.interceptors.request.use(function (config) {
    // Do something before request is sent
    // console.log('config', config);
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// 响应拦截器
axiosClient.interceptors.response.use(function (response) {
    if (!response.data||(response.data.Code!==200&&response.data.code!==311)) {
        console.log('response', response)
        throw new Error(`请求出错啦,错误信息${response.data.message}`)
    }
    return response.data;
}, error => Promise.reject(error));

const errorHandler = (error) => {
    message.error(error.message || error.msg || '访问后台接口失败');
    return Promise.reject(error);
};

const $http = {
    get: (path, data) => axiosClient.get(path,data).catch(errorHandler),
    post: (path, data) => axiosClient.post(path,data).catch(errorHandler),
    delete:path => axiosClient.delete(path).catch(errorHandler),
    patch: (path, data) => axiosClient.patch(path,data).catch(errorHandler),
}
export default $http;
