import { React } from 'react'
import { FaHome } from 'react-icons/fa';
import { BsPlusSquareDotted, BsPlusSquare } from "react-icons/bs";
import { SiOpenai } from "react-icons/si";
import './index.css'
import { useAppContext } from '../../context'
import { Switch } from 'antd'
import { Link, useParams } from 'react-router-dom'
import Search from '../Search'
import { Row, Col } from 'antd'
import _ from 'lodash'
export default function Head({ addSheetTree, addNodeData, openAiShow }) {
  const [ctx, setCtx] = useAppContext()
  let currentNodeName;
  const allData = ctx.getIn(['allData'])
  let params = useParams()
  if (!_.isEmpty(params['*']) && params['*'] !== "home") {
    currentNodeName = _.find(allData.SheetTree, obj => obj.Id === parseInt(params['*'].split("/").pop())).NodeName
  }
  const change = (val) => {
    allData.show = !val
    setCtx(['allData', allData])
    // setCtx(['operateNodeData',{openNodeData:false}])
  }
  return (
    <>
      <div className='headerWrapper'>
        <Row>
          <Col span={1} />
          <Col span={5} style={{ "textAlign": "right" }}>
            <Link to="/home" className="iconCommon" style={{ "fontSize": "1.5rem" }}><span style={{ "margin": "0 5px" }}>{currentNodeName}</span><FaHome /></Link>
          </Col>
          <Col span={12}>
            <Search />
          </Col>
          <Col span={4}>
            <div className='headWrap'>
              <div className='switchWrap'>
                <Switch title='开启关闭编辑' className="iconCommon" checkedChildren="开启" unCheckedChildren="关闭" onChange={change} />
              </div>
              <div>
                <span title='SheetTree' className="iconCommon" style={{ "fontSize": "1.5rem" }} onClick={addSheetTree}><BsPlusSquare /></span>
                <span title='NodeData' className="iconCommon" style={{ "fontSize": "1.5rem" }} onClick={addNodeData}><BsPlusSquareDotted /></span>
                <span title='OpenAi' className="iconCommon" style={{ "fontSize": "1.5rem" }} onClick={openAiShow}><SiOpenai /></span>
              </div>
            </div>
          </Col>
          <Col span={3} />
        </Row>
      </div>
    </>
  )
}
