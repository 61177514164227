import React from 'react';
import MDEditor from '@uiw/react-md-editor';
export const MarkDownViewer = ({data}) => {
    return (
        <div>
            
        </div>
    );
}
export const MarkDownEditor = ({data,onchange})=>{
    return (<>
    
    </>)
}
export const MarkDown=({data,onChange,height})=>{
    return (<>
    <div data-color-mode="dark">
        <MDEditor value={data} onChange={onChange} height={height}/>
        </div>
    </>)
}
