import {React,useState} from 'react';
import {Input,Empty} from 'antd'
import { commonService } from '../../service';
import MDEditor from '@uiw/react-md-editor';
const Chat = () => {
    const {Search} = Input
    const [loading, setLoading] = useState(false);
    const [val,setVal] = useState('');
    const onSearch =(value,event)=>{
        setLoading(true)
        commonService.postChat({Question:value}).then(data=>{
            if(data.Code===200){
                console.log(data.Data)
                setVal(data.Data)
              
            }
        }).finally(val=>{
            setLoading(false)
        })
        console.log('search value ',value)
    }
    return (
        <>
        <div>
            <Search placeholder="输入问题" enterButton="search" loading = {loading} size="large" onSearch={onSearch}/>
        </div>
        <div style={{marginTop:20}}>
        {
            
            val===''?<Empty description={false} />:
            <div data-color-mode="dark">
            <MDEditor.Markdown
                style={{ padding: 15,overflowY:'auto',borderRadius:10 }}
                source={val}
                linkTarget="_blank"
            />
        </div>
        }
         </div>
        </>
    );
}

export default Chat;
