import {React} from 'react';
import { Select } from 'antd';
import { useAppContext } from '../../context';
import _ from 'lodash'
import { useNavigate } from 'react-router-dom';
const App = () => {
  const navigate = useNavigate()
  const [ctx] = useAppContext()
  const allData = ctx.getIn(['allData'])
  const options = []
  _.each(allData.NodeData,item=>options.push({label:_.find(allData.SheetTree,obj=>obj.Id===item.TreeNodeId).NodeName+' > '+ item.Title,value:`${item.Id},${item.TreeNodeId}`}))
  const onSelect = val=>{
    navigate('/home/nodeData/'+val.split(',')[1])
  }
  return (
  <>
  <Select
    showSearch
    size="large"
    bordered="false"
    onSelect={onSelect}
    style={{
      width: '100%',
      height:'50'
    }}
    placeholder="输入要搜索的内容"
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').includes(input)}
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    options={options}
  />
  </>
)};
export default App;