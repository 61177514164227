import React, { useEffect } from 'react';
import { Drawer, Button, Space } from 'antd';
export default function MyDraver({ drawerVisible, setDrawerVisible, editData, form, title,children }) {
    useEffect(() => {
        //组件挂载完毕填充表单
        if (editData) {
            editData = editData.toJS();
        }
        form.setFieldsValue(editData)
    }, [editData])
    const onClose = () => {
        form.resetFields();
        setDrawerVisible(false);
    };
    const submitForm = () => {
        //提交表单
        form.submit();
    }
    return (
        <>
            <Drawer
                forceRender
                destroyOnClose={true}
                title={title}
                width={720}
                onClose={onClose}
                open={drawerVisible}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onClose}>取消</Button>
                        <Button onClick={submitForm} type="primary">
                            提交
                        </Button>
                    </Space>
                }
            >
             {children}   
            </Drawer>
        </>
    )
}
